import { Routes, RouterModule } from '@angular/router';
import { SimulatorGuard } from '@core/guards/simulator.guard';
import { ModuleWithProviders } from '@angular/core';

export const ROUTES: Routes = [
    {
        path: '',
        pathMatch: 'prefix',
        loadChildren: () => import('@modules/searcher/searcher.module').then(m => m.SearcherModule)
    },
    {
        path: 'map',
        pathMatch: 'prefix',
        canActivate: [SimulatorGuard],
        loadChildren: () => import('@modules/map/map.module').then(m => m.MapModule)
    },
    {
        path: 'house-information',
        pathMatch: 'prefix',
        canActivate: [SimulatorGuard],
        loadChildren: () => import('@modules/house-information/house-information.module').then(m => m.HouseInformationModule)
    },
    {
        path: 'results-page',
        pathMatch: 'prefix',
        canActivate: [SimulatorGuard],
        loadChildren: () => import('@modules/simulation/simulation.module').then(m => m.SimulationModule)
    },
    {
        path: 'riepilogo',
        pathMatch: 'prefix',
        canActivate: [SimulatorGuard],
        loadChildren: () => import('@modules/register/register.module').then(m => m.RegisterModule)
    },
    {
        path: 'grazie-budget',
        pathMatch: 'prefix',
        canActivate: [SimulatorGuard],
        loadChildren: () => import('@modules/thanks/thanks.module').then(m => m.ThanksModule)
    },
    {
        path: 'create-password',
        pathMatch: 'prefix',
        loadChildren: () => import('@modules/create-password/create-password.module').then(m => m.CreatePasswordModule)
    },
    {
        path: 'login',
        pathMatch: 'prefix',
        loadChildren: () => import('@modules/login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'lost-password',
        pathMatch: 'prefix',
        loadChildren: () => import('@modules/lost-password/lost-password.module').then(m => m.LostPasswordModule)
    }    
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(ROUTES);