import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })

export class ModalService {
    private modals: any[] = [];

    /**
     * Add modal to array of active modals
     * @param modal: modal component
     */
    add(modal: any): void {
        this.modals.push(modal);
    }

    /**
     * Remove modal from array of active modals
     * @param id: modal id
     */
    remove(id: string): void {
        this.modals = this.modals.filter(x => x.id !== id);
    }

    /**
     * open modal specified by id
     * @param id: modal id
     */
    open(id: string): void {
        const modal = this.modals.find(x => x.id === id);
        modal.open();
    }

    /**
     * close modal specified by id
     * @param id: modal id
     */
    close(id: string): void {
        const modal = this.modals.find(x => x.id === id);
        modal.close();
    }
}