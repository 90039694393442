import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
    providedIn: 'root'
})

export class WpService {
    
    // URL backend WP
    public API = `${environment.wpURL}/wp-json/options/all`;
    public APIMEDIA = `${environment.wpURL}/media`;
  
    data: any = [];
    dataCSV: any = [];
    dataHeader: any = [];
    dataFooter: any = [];
    dataThemeExtra: any = [];
    dataThemeAdvanced: any = [];
    dataAddressHeader: any = [];
    dataHomeSection1: any = [];
    dataHomeSection2: any = [];
    dataHomeSection3: any = [];
    dataSimulationStep1: any = [];
    dataSimulationStep2: any = [];
    dataSimulationStep3: any = [];
    dataSimulationStep4: any = [];
    dataForm: any = [];    
    dataThanksYouBanner: any = [];
    dataThanksYouSimple: any = [];
    dataThanksYou: any = [];
    dataLogin: any = [];
    dataRecoverPassword: any = [];
    dataCreatePassword: any = [];
    dataError: any = [];
    dataModalExit: any = [];
    dataTableTags: any = [];
    dataVariables: any = [];
    dataTagCommander: any = [];
    dataAdobeTarget: any = [];
    
    constructor(
        private http: HttpClient
    ) {}

    /**
     * Get data single page
     * @param id
     */
    getDataFields(): Promise<any> {        
        return this.http.get(this.API).toPromise();
    }
    
    /**
     * Get data single page
     * @param id
     */
    getSinglePage(id: number): Promise<any> {
        const url = `${this.API}/${id}?_fields=acf&acf_format=standard`;
        return this.http.get(url).toPromise();
    }

    /**
     * Get data single media
     * @param id
     */
    getSingleMedia(id: number): Promise<any> {
        const url = `${this.APIMEDIA}/${id}`;
        return this.http.get(url).toPromise();
    }
}