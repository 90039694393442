import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EzzModalComponent } from './ezz-modal/ezz-modal.component';

@NgModule({
    declarations: [
        EzzModalComponent
    ],
    imports: [
        CommonModule
    ],
    exports: [
        EzzModalComponent
    ]
})

export class EzzModalModule {}