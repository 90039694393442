import { FormGroup } from '@angular/forms';

export function ConfirmedValidator(controlName: string, matchingControlName: string){
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        
        if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
            return;
        }
        
        control.value !== matchingControl.value ? matchingControl.setErrors({ confirmedValidator: true }) : matchingControl.setErrors(null);
    }
}