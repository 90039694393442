import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalService } from './../ezz-modal.service';
import { WpService } from '@core/services/wp.service';
import { GeneralInfoService } from '@core/services/general-info.service';

interface ModalEvent {
    modalId: string;
    event?: string;
}

@Component({
    selector: 'ezz-modal',
    templateUrl: './ezz-modal.component.pug',
    styleUrls: ['./ezz-modal.component.sass']
})

export class EzzModalComponent implements OnInit, OnDestroy {

    private readonly element: any;

    // Inputs
    @Input() id: string;
    @Input() hasCloseButton: boolean = true;
    @Input() cancelButtonText: string = 'cancel';
    @Input() submitButtonText: string = 'submit';
    @Input() modalTitle: string = '';
    @Input() allowCloseOnBackdrop: string | boolean = false;
    @Input() confirmModal: string | boolean = false;
    @Input() submitButtonDisabled: boolean = false;
    @Input() submitButtonLoading: boolean = false;
    @Input() icon: ModalIcon | boolean = false;
    @Input() noPadding: boolean = false;
    @Input() overflowVisible: boolean = false;
    @Input() toolTip: string | boolean = false;
    @Input() zIndex: string;
    @Input() width: string;
    @Input() loading: string;
    // Alias to set footer to false
    @Input() hasHeader: boolean = true;
    @Input() noHeader: string | boolean = false;
    @Input() hasFooter: boolean = true;
    @Input() noFooter: string | boolean = false;

    // Outputs
    @Output() submitModal: EventEmitter<ModalEvent> = new EventEmitter<ModalEvent>();
    @Output() isOpen: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() closeModal: EventEmitter<ModalEvent> = new EventEmitter<ModalEvent>();

    // Others
    @HostListener('document:keydown', ['$event']) handleKeyboardEvent = (event: KeyboardEvent) => {
        if (event.code === 'Escape') {
            this.close();
        }
    }

    constructor(
        private modalService: ModalService,
        private el: ElementRef,
        public wp: WpService,
        public generalInfoService: GeneralInfoService,
    ) {
        this.element = this.el.nativeElement;
    }

    /**
     * Init data
     */
    ngOnInit(): void {
        // move element to bottom of page (just before </body>) so it can be displayed above everything else
        document.body.appendChild(this.element);

        // close modal on background click
        this.element.addEventListener('click', el => {
            if (this.allowCloseOnBackdrop) {
                if (el.target.classList[0] === 'ezz-modal-wrapper') {
                    this.close();
                }
            }
        });

        // add self (this modal instance) to the modal service so it's accessible from controllers
        this.modalService.add(this);
    }

    /**
     * remove self from modal service when component is destroyed
     */
    ngOnDestroy(): void {
        this.modalService.remove(this.id);
        this.element.remove();
    }

    /**
     * Open modal and focus on first input (if exists)
     */
    open(): void {
        this.element.style.display = 'block';
        this.element.style.position = 'relative';
        this.element.style.zIndex = '9999';
        document.body.classList.add('ezz-modal-open');
        this.isOpen.emit(true);

        // Focus first input (not checkbox, toggles and radios)
        setTimeout(() => {
            const inputElements = [...this.element.querySelectorAll('input, textarea')]
                .filter(input => input.type !== 'checkbox' && input.type !== 'radio')
                .filter(input => !input.disabled && !input.readOnly);
            if (inputElements.length) { inputElements[0].focus(); }
        });
    }

    /**
     * Close modal
     */
    close(): void {
        this.element.style.display = 'none';
        document.body.classList.remove('ezz-modal-open');
        this.isOpen.emit(false);

        this.generalInfoService.viewModalLead = false;   
        this.generalInfoService.viewModalExit = false;
    }

    /**
     * Function that handles the click on cancel button
     */
    handleCancelButton(): void {
        this.closeModal.emit({ modalId: this.id, event: 'cancel' });
        this.close();
    }

    /**
     *  Function that handles the click on submit button
     */
    handleSubmitButton(): void {
        this.submitModal.emit({ modalId: this.id, event: 'submit' });
    }

    /**
     * Function that handles the click on close button
     */
    handleCloseButton(): void {
        this.closeModal.emit({ modalId: this.id, event: 'close' });
        this.close();
    }
}