import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from './footer.component';
import { EzzModalModule } from '@shared/components/ezz-modal-module';
import { RegisterFormModule } from '@shared/components/register-form/register-form.module';

@NgModule({
    declarations: [FooterComponent],
    imports: [
        CommonModule,
        EzzModalModule,
        RegisterFormModule
    ],
    exports: [
        FooterComponent
    ]
})

export class FooterModule {}