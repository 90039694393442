import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

interface Cp {
    opportunity_type_id: string;
    house_consumption: number;
    latitude: number;
    longitude: number;
    modules_number: number[];
    price_table_tag: string;
}

interface Profitability {
    opportunity_type_id: string;
    house_consumption: number;
    latitude: number;
    longitude: number;
    modules_number: number[];
    price_table_tag: string;
}

interface LoginResponse {
    preflight: string;
    companies?: (CompaniesEntity)[] | null;
    agent_data: AgentData;
    auth_provider_id: number;
}

interface CompaniesEntity {
    id: number;
    name: string;
}

interface AgentData {
    type: string;
    name: string;
}

@Injectable({
    providedIn: 'root'
})

export class ApiService {

    constructor(
        private httpClient: HttpClient
    ) {}

    /**
     * Function for get Cp optimums
     * @param data
     */
    getCp(data: Cp): any {
        return this.httpClient.post(`${environment.apiURL}/api/profitability/cp`, data);
    }

    /**
     * Function to get profitability
     * @param data
     */
    getProfitability(data) {
        return this.httpClient.post(`${environment.apiURL}/api/profitability/simulation`, data);
    }

    /**
     * Function to get modules by surface
     * @param data
     */
    getModulesBySurface(data) {
        return this.httpClient.post(`${environment.apiURL}/api/profitability/getModulesBySurface`, data);
    }

    /**
     * Function to save client
     * @param clientData
     */
    saveClient(clientData) {
        return this.httpClient.post(environment.apiURL + '/api/client', {
            ...clientData
        });
    }

    /**
     * Function to save lead
     * @param clientData
     */
    saveLead(clientData) {
        return this.httpClient.post(environment.apiURL + '/api/lead', {
            ...clientData
        });
    }

    /**
     * Function to login
     * @param data
     */
    clientLogin(data): Observable<LoginResponse> {
        return this.httpClient.post<LoginResponse>(`${environment.apiURL}/login`, data);
    }

    /**
     * Function to forgot password
     * @param data
     */
    forgotPassword(data): Observable<LoginResponse> {
        return this.httpClient.post<LoginResponse>(`${environment.apiURL}/api/client/forgot-password`, data);
    }
}