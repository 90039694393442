import { Component, HostListener, OnInit } from '@angular/core';
import { GeneralInfoService } from '@core/services/general-info.service';
import { WpService } from '@core/services/wp.service';
import { UtilsService } from '@core/services/utils.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.pug',
    styleUrls: ['./header.component.sass']
})

export class HeaderComponent implements OnInit {

    activeToggle: boolean = false;
    lastScrollTop = 0;
    delta = 5;
    hideNav: boolean = false;
    navbarHeight = 100;
    isMobile: boolean = false;

    constructor(
        public generalInfoService: GeneralInfoService,
        public wp: WpService,
        public utilsService: UtilsService,
    ) {}

    @HostListener('document:scroll', ['$event']) onScroll($event: any) {
        this.hasScrolled();
    }

    ngOnInit(): void {
        this.isMobile = UtilsService.isMobileDevice();           
    }
    
    /*
    * Activate/Deactivate className 'toogled' for show/hide steps Mobile
    */
    activateToggle() {
        this.activeToggle = !this.activeToggle;
    }


    hasScrolled() {
        var st = document.documentElement.scrollTop;

        if (Math.abs(this.lastScrollTop - st) <= this.delta)
            return;

        this.hideNav = (st > this.lastScrollTop && st > this.navbarHeight) ? true : false;
        this.lastScrollTop = st;
    }
}