import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { GeneralInfoService } from '@core/services/general-info.service';

@Injectable({
    providedIn: 'root'
})

export class SimulatorGuard implements CanActivate {

    constructor(
        private generalInfoService: GeneralInfoService,
        private router: Router
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (
            state.url.includes('map') || 
            state.url.includes('house-information') || 
            state.url.includes('results-page') || 
            state.url.includes('riepilogo') || 
            state.url.includes('grazie-budget')) {
            if (!this.generalInfoService.place && !this.generalInfoService.urlPlace) {
                this.router.navigate(['']).then();
                return false;
            }
        }
        return true;
    }
}