import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROUTES } from '@shared/routes/routes';
import { LayoutComponent } from '@core/layout/layout.component';

const routes: Routes = [
    {
        path: '',
        component: LayoutComponent,
        children: ROUTES
    },
    { path: '**', redirectTo: 'error/404' },
    { path: 'error', loadChildren: () => import('@modules/error/error.module').then(m => m.ErrorModule) }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
    exports: [RouterModule]
})

export class AppRoutingModule {}