export const environment = {
    production: '{PRODUCTION}',
    apiURL: '{APIURL}',
    wpURL: '{WPURL}',
    googleAPIKey: '{GOOGLEAPIKEY}',
    sentryDsn: '{SENTRYDSN}',
    portalClientUrl: '{PORTALCLIENTURL}',
    opportunityTypeId: '{OPPORTUNITYTYPEID}',
    campaignHash: '{CAMPAIGNHASH}',
    companyId: '{COMPANYID}',
    countryId: '{COUNTRYID}',
    clientTypeID: '{CLIENTTYPEID}',
    countryCode: '{COUNTRYCODE}'
};