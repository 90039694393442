import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { WpService } from '@core/services/wp.service';

@Component({
    selector: 'app-button',
    templateUrl: './button.component.pug',
    styleUrls: ['./button.component.sass']
})

export class ButtonComponent implements OnInit {
    @Input() iconPhone: boolean | string;
    @Input() selected: boolean | string;
    @Input() primary: boolean | string;
    @Input() lined: boolean | string;
    @Input() lined2: boolean | string;
    @Input() secondary: boolean | string;
    @Input() accent: boolean | string;
    @Input() loading: boolean;
    @Input() disabled: boolean;
    @Output() buttonClick: EventEmitter<number> = new EventEmitter<number>();

    constructor(
        public wp: WpService
    ) { }

    /**
     * Init
     */
    ngOnInit(): void {  
        this.iconPhone = this.iconPhone === '';     
        this.selected = this.selected === '';     
        this.primary = this.primary === '';
        this.lined = this.lined === '';
        this.lined2 = this.lined2 === '';
        this.secondary = this.secondary === '';
        this.accent = this.accent === '';

        if (!this.primary && !this.secondary && !this.accent) {
            this.primary = true;
        }
    }

    /**
     * Function that handle button click
     */
    handleButtonClick(): void {
        this.buttonClick.emit(2);
    }
}