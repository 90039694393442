import { Component, ViewEncapsulation } from '@angular/core';
import { WpService } from '@core/services/wp.service';
import { GeneralInfoService } from '@core/services/general-info.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.pug',
    styleUrls: ['./footer.component.sass'],
    encapsulation: ViewEncapsulation.None
})

export class FooterComponent  {
    year = new Date().getFullYear();

    constructor(
        public wp: WpService,
        public generalInfoService: GeneralInfoService,
    ) {}
}