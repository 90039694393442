import { Injectable, NgZone } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

export const Irradiation = {
    performanceRatio: 0.8
};

@Injectable({
    providedIn: 'root'
})

export class UtilsService {
    isScriptInjected: boolean;
    showPrivacy: boolean = true;
    irradiationLoading = false;
    systemPower: number;
    installationPower: number;
    static readonly mobileRE: RegExp = new RegExp('/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i');
    static readonly tabletRE: RegExp = new RegExp('/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino|android|ipad|playbook|silk/i');

    constructor(
        private ngZone: NgZone,
        public http: HttpClient
    ) {}

    /**
     * Get irradiation and calculate system power
     * @param lat
     * @param lng
     */
    getIrradiationAndCalculateSystemPower(lat: number, lng: number): void {
        const self = this;

        this.ngZone.run(() => {
            self.irradiationLoading = true;

            setTimeout(() => {
                self.getIrradiationByLatLng(lat, lng)
                    .subscribe(result => {
                        self.calculateInstallationDetails(result.year_irradiation);
                        self.irradiationLoading = false;
                    });
            }, 1200);
        });
    }

    /**
     * Get irradiation
     */
    getIrradiationByLatLng(lat: number, lng: number): Observable<any> {
        const self = this;
        const transfactorUrl = environment.apiURL + '/irradiation/transfactors';

        const data = {
            latitude: lat,
            longitude: lng,
        };

        return self.http.post(transfactorUrl, data);
    }

    /**
     * Calculate system power for the actual simulation
     * @param area
     * @param yearIrradiation
     */
    calculateInstallationDetails(yearIrradiation: number): void {
        const self = this;

        this.ngZone.run(() => {
            self.systemPower = yearIrradiation;
            self.installationPower = yearIrradiation * Irradiation.performanceRatio;
        });
    }


    /**
     * The function check the browser in use and return if is on mobile device
     */
    static isMobileDevice(opts?: any): boolean {
        if (screen.width < 740) {
            return true;
        }

        if (!opts) {
            opts = {};
        }   
        
        let ua = opts.ua;

        if (!ua && typeof navigator !== 'undefined') {
            ua = navigator.userAgent;
        }

        if (ua && ua.headers && typeof ua.headers['user-agent'] === 'string') {
            ua = ua.headers['user-agent'];
        }

        if (typeof ua !== 'string') {
            return false;
        }

        return opts.tablet ? UtilsService.tabletRE.test(ua) : UtilsService.mobileRE.test(ua);
    }
}